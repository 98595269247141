<template>
  <div class="template-form">
    <v-form ref="form" class="template-form__form" @submit.prevent="submit">
      <h3 class="py-5 primary--text">{{ header }}</h3>

      <v-row>
        <v-col cols="12" md="6">
          <label class="text-field-label">Subject</label>
          <v-text-field
            flat
            solo
            required
            class="mt-2"
            placeholder="Subject"
            v-model="form.subject"
            :error-messages="form.$getError('subject')"
            :loading="form.$busy"
            :disabled="form.$busy"
          ></v-text-field>

          <label class="text-field-label">Email Body</label>
          <content-editor
            ref="textArea"
            v-model="form.description"
            @input="updateContent"
          />
        </v-col>

        <v-col cols="12" md="6">
          <h3 class="mb-4">Insert Merge Field</h3>
          <v-expansion-panels>
            <v-expansion-panel
              v-for="(group, index) in mergeFields"
              :key="index"
            >
              <v-expansion-panel-header @click="insertField">
                {{ index }}
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <ul class="merge-fields">
                  <li
                    v-for="(field, idx) in group"
                    :key="idx"
                    class="my-2"
                    @click="insertField(field)"
                  >
                    {{ field.visual_field_name }}
                  </li>
                </ul>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" md="6">
          <div class="mt-4">
            <v-btn
              v-if="hasPermissions"
              type="submit"
              color="primary"
              class="mr-4 px-6"
              height="40px"
              width="100%"
              :loading="form.$busy"
              >{{ buttonLabel }}
            </v-btn>
          </div>
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import SnackbarMixin from '@/utils/mixins/Snackbar'
import ErrorHandlerMixin from '@/utils/mixins/ErrorHandler'
import Form from '@/utils/form'
import { validatePermissions } from '@/utils/auth'
import PERMISSION from '@/utils/enums/Permission'
import { mdiPlus } from '@mdi/js'
import ContentEditor from '@/views/Home/Settings/PageManager/components/ContentEditor.vue'

export default {
  components: { ContentEditor },
  mixins: [SnackbarMixin, ErrorHandlerMixin],

  props: {
    template: {
      type: Object,
      default: () => null,
    },
    mergeFields: {
      type: [Object, Array],
      default: () => [],
    },
  },

  data() {
    return {
      form: new Form({
        subject: '',
        description: '',
      }),
      loading: false,
      icons: {
        add: mdiPlus,
      },
    }
  },

  computed: {
    ...mapState({
      permissions: (state) => state.auth.permissions,
    }),

    header() {
      return this.isUpdate ? 'Template Information' : 'Create Template'
    },

    buttonLabel() {
      return this.isUpdate ? 'Update' : 'Create'
    },

    submitApi() {
      return this.isUpdate ? this.updateTemplate : this.createTemplate
    },

    hasPermissions() {
      return validatePermissions(
        [PERMISSION.TEMPLATES_UPDATE],
        this.permissions
      )
    },

    isUpdate() {
      return !!this.template
    },
  },

  watch: {
    template() {
      this.initForm()
    },
  },

  methods: {
    ...mapActions({
      createTemplate: 'template/createTemplate',
      updateTemplate: 'template/updateTemplate',
    }),

    initForm() {
      if (this.template) {
        this.form.subject = this.template.subject
        this.form.description = this.template.description
      }
    },

    async submit() {
      if (this.form.$busy || !this.validate()) return

      this.form.$busy = true
      this.form.$clearErrors()

      let forUpdate = this.isUpdate
      await this.submitApi(this.getFormData())
        .then(() => {
          this.form.$busy = false
          this.showSnackbar(
            forUpdate
              ? 'Template details successfully updated!'
              : 'Template successfully created!'
          )
          if (!forUpdate) {
            this.$router.push({
              name: 'settings.organisation',
              params: { tab: 'templates' },
            })
          }
        })
        .catch((err) => {
          this.form.$busy = false
          this.form.$setErrors(this.getValidationErrors(err))
        })
    },

    validate() {
      this.form.$clearErrors()
      return this.isUpdate || !this.form.$hasErrors()
    },

    getFormData() {
      let formData = this.form.$data()
      if (this.isUpdate) {
        formData.id = this.template.id
      }
      return formData
    },

    insertField(field) {
      const fieldText = field.visual_field_name
        ? `{{${field.visual_field_name}}}`
        : ''

      const editor = this.$refs.textArea

      const { from, to } = editor.$refs.editor.editor.state.selection

      const transaction = editor.$refs.editor.editor.state.tr.insertText(
        fieldText,
        from,
        to
      )

      editor.$refs.editor.editor.view.dispatch(transaction)

      this.$nextTick(() => {
        const newFrom = from + fieldText.length
        const newTransaction = editor.$refs.editor.editor.state.tr.setSelection(
          editor.$refs.editor.editor.state.selection.constructor.near(
            editor.$refs.editor.editor.state.doc.resolve(newFrom)
          )
        )

        editor.$refs.editor.editor.view.dispatch(newTransaction)
        editor.$refs.editor.editor.view.focus()
      })
    },

    updateContent(value) {
      this.form.description = value
    },
  },
}
</script>

<style lang="scss">
.template-form {
  @media (max-width: 768px) {
    &__form {
      max-width: 100%;
    }
  }

  .hint {
    font-size: 12px;
    color: #888;
  }

  .merge-fields {
    list-style-type: none;

    li {
      cursor: pointer;
    }
    li:hover {
      text-decoration: underline;
    }
  }
}
</style>
